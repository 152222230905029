/*-----------------------------------------------
|   Reboot
-----------------------------------------------*/
::placeholder {
  opacity: 1;
  color: var(--#{$variable-prefix}400);
}

::selection {
  background-color: map_get($theme-colors, primary);
  color: $white;
}

html {
  scroll-padding-top: $top-nav-height;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
}

pre,
code {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

section {
  position: relative;
  padding-top: map_get($spacers, 6);
  padding-bottom: map_get($spacers, 6);
  scroll-margin-top: -1.5625rem;

  @include media-breakpoint-up(md) {
    padding-top: map_get($spacers, 8);
    padding-bottom: map_get($spacers, 8);
  }
}

//setting up font family for bootstrap components
select,
optgroup,
textarea,
label,
.alert,
.badge,
.blockquote-footer,
.pagination,
.valid-feedback,
.invalid-feedback {
  font-family: $font-family-base;
}

label{
  font-family: $font-family-sans-serif;
  font-size: .8rem;
  font-weight: $font-weight-semi-bold !important;
}

input,
button,
.btn,
.navbar{
  font-family: $font-family-sans-serif;
}

pre{
  margin: 0;
  transform: translate3d(0, 0, 0);
}

strong {
  font-weight: $font-weight-bold;
}

// label {
//   font-weight: $font-weight-medium !important;
//   margin-bottom: map-get($spacers, 2);
// }

pre,
code,
kbd,
samp {
  font-size: 0.875rem !important;
}

ol{
  padding-left: $spacer * 2.5;
}
ul{
  font-family: $font-family-sans-serif;
  color: $gray-1100;
}

small{
  font-weight: $font-weight-normal !important;
  font-size: 12.8px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-bottom: 0.5rem;
}

h2, h3, h4, h5 .h2 .h3 .h4 h5{
	font-weight: 400;
  line-height: 1.2;
}

// h1, .h1, h3, .h3{
//   line-height: 1;
// }

h4{
  margin-bottom: 1.25rem !important;
}

h5{
	font-weight: 600;
}

h6{
  font-weight: 700;
  text-transform: uppercase;
	letter-spacing: 0.06em;
  // line-height: 1;
}

p{
  font-weight: 400;
  color: $gray-700;
  &.lead{
    font-weight: 400;
  }
}

/*-----------------------------------------------
|   Horizontal rules
-----------------------------------------------*/
hr {
  background-color: $gray-500;
  margin: auto;
  &.left {
    margin-left: 0;
  }
  &.short {
    width: 3.05176rem;
  }
}

