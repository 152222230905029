@each $color, $value in $theme-colors {
  .link-#{$color}{
    color: var(--#{$variable-prefix}#{$color});
    &:hover,
    &:focus{
      color: var(--#{$variable-prefix}hover-link-#{$color})
    }
  }
}

@each $color, $value in $grays {
  .link-#{$color} {
    color: var(--#{$variable-prefix}#{$color}) !important;
    &:hover,
    &:focus {
      color: var(--#{$variable-prefix}hover-link-#{$color}) !important;
    }
  }
}
