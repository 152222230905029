/*-----------------------------------------------
|   Input with icon
-----------------------------------------------*/
.input-iconic{
  position: relative;
  button{
    outline: 0;
    background-color: transparent;
    color: $gray-600;
    border: 0;
  }
  .input-icon{
    position: absolute;
    z-index: 99;
    right: map_get($spacers, 4);
    top: 50%;
    transform: translateY(-50%);
  }
  .form-control{
    padding-right: map_get($spacers, 5);
    border-color: $gray-300;
  }
}
.custom-control-label{
  line-height: 1rem;
}
.custom-file-label,
.custom-file-label:after{
  line-height: 1.25rem;
}

.contact-message {
  height: 11.375rem !important;
}

/*-----------------------------------------------
|   Icon with input
-----------------------------------------------*/

.input-group-icon{
	position: relative;
	.input-icon {
		position: absolute;
		z-index: 99;
		right: map_get($spacers, 3);
		font-size: modular-scale(0);
		top: 50%;
		transform: translateY(-50%);
    a{
      color: $gray-500;
    }
	}
	.form-control{
		font-size: 1rem;
    height: 38.7812px;
		padding-left: map_get($spacers, 3);
		padding-right: map_get($spacers, 5);
    background-color: transparent;
    &:focus {
      background-color: $input-focus-bg;
    }
	}
}

.input-group-text {
  padding: 0.375rem 0.75rem;
}

/*-----------------------------------------------
|   Checkbox and Radio
-----------------------------------------------*/
.zinput {
  position: relative;
}

.zinput label {
  display: inline-block;
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.zinput label:before {
  width: 32px;
  height: 32px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
  content: '';
  border: 1px solid #e1e1e1;
  transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  box-shadow: inset 0 0 0 0px #4CD964, 0 0 0 0 #e1e1e1;
}

.zinput.zradio {
  padding: 0.8rem 0;
}

.zinput.zradio input[type='radio'] {
  opacity: 0;
  width: 0;
  height: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
}

.zinput.zradio input[type='radio']:checked + label:before {
  border-color: transparent;
}

.zinput.zradio input[type='radio'] + label:before {
  border-radius: 50px;
}

.zinput.zradio input[type='radio']:checked + label:before {
  box-shadow: inset 0 0 0 9px #4CD964, 0 0 0 0 #4CD964;
}