.no-circle{
	position: relative;
	>span{
		position: absolute;
		padding: map-get($spacers, 2);
		text-align: center;
		color: white;
		border-radius: 100%;
		height: 40px;
		width: 40px;
		top: 0;
		left: -20px;
		font-weight: 700;
	}
}