/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;

  &.bg-right {
    left: auto;
    right: 0;
  }

  &.overlay {
    &:before{
      position: absolute;
      content: "";
      background: rgba($black, 0.3);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  
  &.overlay-gradient:before {
    background: linear-gradient(-45deg, rgba(0, 25, 66, 0.702) 0, rgba(244,244,234,0.5) 100%);
  }
  
  .bg-video {
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
  }

  .bg-youtube {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.bg-fixed {
  background-attachment: fixed;
}

.header-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mb_YTPBar{
  display: none !important;
}
