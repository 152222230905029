/*-----------------------------------------------
|   Documentation link and Prism
-----------------------------------------------*/
:not(pre) > code[class*='language-'], pre[class*='language-'] {
  background-color: $gray-100;
  border: $border-width solid $gray-100;
}

code[class*='language-'], pre[class*='language-'] { font-family: $font-family-monospace; }
.contains-anchor{
  a{
    opacity: 0;
    transition-property: opacity;
  }
  @include hover-focus{
    a{
      opacity: 1;
      text-decoration: none;
    }
  }
}

.component-preview{
  // border: 0.125rem solid $gray-100;
  border-radius: $border-radius;
}

/*-----------------------------------------------
|   Component examples
-----------------------------------------------*/
/*-----------------------------------------------
|   Borders
-----------------------------------------------*/
.component-example{
  font-family: $font-family-sans-serif;
  [class^='border']{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    margin: .75rem;
    background-color: $gray-100;
  }
}
/*-----------------------------------------------
|   Grid
-----------------------------------------------*/
.grid-component [class^=col]{
  border: 1px solid map-get($grays, '400');
  text-align: center;
  margin-bottom: 0.625rem;
  color: map-get($grays, '700');
  padding: 0.5rem;
  font-size: map-get($font-sizes, '-1' );
}

